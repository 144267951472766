import { Form, href, Link, useNavigation } from "react-router";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef } from "react";
import { DialogWindow, ITeam } from "~/utils/api.interfaces.enums";
import { MixPanel } from "~/utils/MixPanel";
import TeamNameAndLoader from "~/components/PagesLayoutComponents/sidebar/TeamNameAndLoader";
import { MingleBadges } from "../../MingleBadges";

declare let window: DialogWindow;
export default function TeamDropDown({
  activeTeam,
  teams,
}: {
  activeTeam: ITeam | undefined;
  teams: ITeam[];
}) {
  const { t } = useTranslation("translation");
  let navigation = useNavigation();
  let isSubmitting =
    navigation.state === "submitting" || navigation.state === "loading";
  let teamDropdownRef = useRef<HTMLDetailsElement>(null);

  useEffect(() => {
    window.addEventListener("click", (e: MouseEvent) => {
      if (
        teamDropdownRef?.current &&
        !teamDropdownRef?.current?.contains(e.target as Node)
      ) {
        teamDropdownRef.current.open = false;
      }
    });
  }, []);
  return (
    <>
      <ul className={`menu p-0 mb-2 z-20  w-full `}>
        <li>
          <details
            className={`rounded-t-md rounded-b-[0px]  bg-white/[.05] border-white/10 border rounded-lg relative w-full`}
            ref={teamDropdownRef}
          >
            <summary
              tabIndex={0}
              className={`flex flex-col  gap-1 items-start after:absolute after:right-5 after:top-6  rounded-t-md rounded-b-[0px] team-dropdown-button pb-4 ${
                isSubmitting ? " cursor-not-allowed after:hidden" : ""
              } `}
            >
              <TeamNameAndLoader activeTeam={activeTeam} />

              <div className={`w-full  flex justify-start items-center  gap-2`}>
                {activeTeam?.organization?.type === "club" && (
                  <div className="badge text-xs py-2">
                    {" "}
                    {activeTeam?.season?.name}
                  </div>
                )}

                {!activeTeam?.isArchived && (
                  <>
                    {" "}
                    {activeTeam?.boostStatus?.boostLevel &&
                    activeTeam?.boostStatus?.boostLevel > 0 ? (
                      <MingleBadges
                        type="boostLevelOne"
                        label={`level ${activeTeam?.boostStatus?.boostLevel}`}
                      />
                    ) : (
                      <MingleBadges type="boostLevelZero" label={`level 0`} />
                    )}
                  </>
                )}
                {activeTeam?.isArchived && (
                  <div className="badge badge-sm py-2  badge-outline text-gray-400">
                    {" "}
                    archived
                  </div>
                )}
              </div>
            </summary>

            <ul
              tabIndex={0}
              className="mt-0 dropdown-content menu shadow-sm bg-base-100 z-20 ml-0 rounded-b-md rounded-t-0 w-full "
            >
              {teams?.map((team: any) => {
                return (
                  <li
                    key={team.id}
                    onClick={() => {
                      if (
                        document.activeElement instanceof HTMLElement &&
                        teamDropdownRef.current
                      ) {
                        document.activeElement.blur();
                        teamDropdownRef.current.open = false;
                        window.sidebar.close();
                      }
                    }}
                  >
                    <Form
                      method="post"
                      action="/action/changeteam"
                      className="text-left p-0 flex flex-row w-full"
                    >
                      <button
                        className="flex gap-2 items-center w-full text-left px-2 py-3 cursor-pointer"
                        type={"submit"}
                        name={"teamId"}
                        value={team?.id}
                        onClick={(e) => {
                          MixPanel.track("mft_common_tap_item_selectTeam");
                        }}
                      >
                        {team?.logoUrl ? (
                          <img
                            alt=""
                            src={team?.logoUrl}
                            className="h-5 w-5 rounded-full object-cover"
                          />
                        ) : (
                          <div className="avatar placeholder">
                            <div className="bg-neutral-focus text-neutral-content rounded-full w-5">
                              <span className="text-sm">
                                {team?.nameAbbreviation}
                              </span>
                            </div>
                          </div>
                        )}
                        {team.name}
                      </button>
                      {team?.organization?.type === "club" && (
                        <div className="badge badge-sm mr-1">
                          {team?.season?.name}
                        </div>
                      )}
                    </Form>
                  </li>
                );
              })}
              <li className="border-t border-gray-600 mt-2">
                <Link
                  to={href("/onboarding/team-setup/what-sport")}
                  className="btn btn-ghost btn-sm font-normal text-xs flex gap-2 items-center w-full text-left justify-start px-2 mt-2"
                >
                  <svg
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill="none"
                    className="h-5 w-5 rounded-full object-cover"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9 15.75a6.75 6.75 0 100-13.5 6.75 6.75 0 000 13.5zm0 1.5A8.25 8.25 0 109 .75a8.25 8.25 0 000 16.5z"
                      fill="#BBC3CF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9 5.25a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0V6A.75.75 0 019 5.25z"
                      fill="#BBC3CF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.75 9a.75.75 0 01-.75.75H6a.75.75 0 010-1.5h6a.75.75 0 01.75.75z"
                      fill="#BBC3CF"
                    />
                  </svg>
                  Add new team
                </Link>
              </li>
            </ul>
          </details>
        </li>
      </ul>
    </>
  );
}
