import { useEffect } from "react";
import {
  ClientLoaderFunctionArgs,
  data,
  Form,
  isRouteErrorResponse,
  LoaderFunctionArgs,
  redirect,
  useLoaderData,
  useOutletContext,
  useRouteError,
} from "react-router";
import {
  ApiEnum,
  DialogWindow,
  ITeam,
  IUserAccount,
  TeamPagesData,
} from "~/utils/api.interfaces.enums";
import { MixPanel } from "~/utils/MixPanel";
import { useFetch } from "~/utils/useFetch.server";

import { MingleSubmitButton } from "~/components/form/MingleSubmitButton";
import { UserRedirectSession } from "~/sessions/userRedirectSession.server";

import { OnboardingSession } from "~/sessions/OnboardingSession.server";

import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import { authenticate, codeLogin } from "~/auth.server";
import { PreuserSession } from "~/sessions/PreuserSession.server";

import { LRUCache } from "lru-cache";
import { FlashMessageSession } from "~/sessions/FlashMessageSession.server";
import { sortMatchByDateAsc } from "~/utils/helperFunctions";

import ModalAdminRights from "~/components/modals/ModalAdminRights";
import ModalBoostLevelRights from "~/components/modals/ModalBoostLevelRights";
import ModalEditUser from "~/components/modals/ModalEditUser";
import ModalLogout from "~/components/modals/ModalLogout";
import ModalTeamIsArchived from "~/components/modals/ModalTeamIsArchived";
import ModalWelcome from "~/components/modals/ModalWelcome";

import { LayoutStructure } from "~/components/PagesLayoutComponents/LayoutStructure";
import { LoadingSkeleton } from "~/components/PagesLayoutComponents/LoadingSkeleton";
import NoTeamsMessage from "~/components/PagesLayoutComponents/NoTeamsMessage";
import {
  calculateTotals,
  initializePlayers,
  processMatches,
  rankPlayers,
} from "~/utils/utils.stats.server";

import { getMingleAuthSessionMethods } from "~/sessions/MingleAuthSessionStorage.server";

declare let window: DialogWindow;

// Initialize an LRU cache with a max of 500 items and a TTL of 60 minutes
export const cache = new LRUCache<string, any>({
  max: 5000,
  ttl: 1000 * 60 * 60,
});

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  // Retrieve the session from the request cookies
  const { getSession, commitSession } = getMingleAuthSessionMethods(context);
  const session = await getSession(request.headers.get("cookie"));
  const preUserSession = await PreuserSession(request);
  const preUser = preUserSession.getPreUser();

  // Parse the current URL and its search parameters
  const currentUrl = new URL(request.url);
  const searchParams = currentUrl.searchParams;

  // Get the authorization code from the URL parameters
  const code = searchParams.get("code") || "";
  let user: IUserAccount | null;
  let sessionCookie: string;

  if (code) {
    // If an authorization code is present, log in the user with the code
    const teamId = searchParams.get("teamId") || "";
    user = (await codeLogin(code, context)) as IUserAccount;
    session.set("user", user);
    if (teamId) session.set("teamId", teamId);
    const redirectUrl =
      currentUrl.pathname + (currentUrl.hash ? "#" + currentUrl.hash : "");
    sessionCookie = await commitSession(session);
    return redirect(redirectUrl, {
      headers: {
        "Set-Cookie": sessionCookie,
      },
    });
  } else {
    // Otherwise, authenticate the user with the request
    user = (await authenticate(request, context)) as IUserAccount;
    // Immediately update session with potentially refreshed user
    session.set("user", user);
    // Commit session to get the cookie
    sessionCookie = await commitSession(session);
  }

  // Redirect to the sign-in page if the user is not authenticated
  if (!user?.token) return redirect("/signin");

  // Redirect to the claim account success page if the pre-user exists
  if (preUser?.id && user?.token) {
    throw redirect(
      `/claim-account/success/${preUser.firstName}/${preUser.team.name}`,
      {
        headers: { "Set-Cookie": await preUserSession.deletePreUser() },
      }
    );
  }
  if (searchParams.get("teamId")) {
    cache.delete("teams");
    cache.delete("activeTeam");
  }

  // Retrieve various sessions and check for flash messages
  const userRedirectSession = await UserRedirectSession(request);
  const onBoardingSession = await OnboardingSession(request);
  const showWelcomeMessage =
    userRedirectSession.getRedirectParams()?.showWelcomeMessage;

  const flashSession = await FlashMessageSession(request);
  const actionMessage = flashSession.getFlashMessage()?._action;
  if (actionMessage) {
    // Optimize cache handling for actions
    switch (actionMessage) {
      case "gotoHomebase":
      case "gotoCreateMatchSetup":
      case "changeTeam": {
        console.log("ALL Cache cleared");
        cache.clear();
        break;
      }
      case "addMatch":
      case "updateMinutesPlayed":
      case "updateRsvp":
      case "deleteMatch":
      case "updateGoals":
      case "editMatch": {
        cache.delete("matches");
        cache.delete("futureMatches");

        console.log("Matches, futureMatches, matches cache cleared");
        break;
      }
      case "editPreUser":
      case "transferTeamMembers":
      case "editMember":
      case "updateTeamLogoSettings":
      case "deleteUser":
      case "addMatchSettings":
      case "updateTeamHeader":
      case "archiveTeam":
      case "unArchiveTeam":
      case "addUser": {
        cache.delete("teams");
        cache.delete("activeTeam");
        break; // Add missing break statement
      }
    }
  }

  // Fetch the logged-in user's data
  // Optimize user fetching with Promise.all for parallel requests
  const [loggedInUser, permissions] = await Promise.all([
    useFetch({
      request,
      context,
      url: "/User/GetCurrentUser",
      method: "GET",
      apiType: ApiEnum.Web,
    }),
    useFetch({
      request,
      context,
      url: "/team/permissions",
      method: "GET",
      apiType: ApiEnum.Mob,
    }),
  ]);

  let updatedLoggedInUser = loggedInUser;
  if (updatedLoggedInUser) {
    updatedLoggedInUser = {
      ...updatedLoggedInUser,
      ...permissions,
    };
  }

  // Redirect to the onboarding welcome page if the user's profile is incomplete
  if (loggedInUser?.email && !loggedInUser?.profileComplete) {
    throw redirect(`/onboarding/welcome`, {
      headers: [
        ["Set-Cookie", sessionCookie],
        ["Set-Cookie", await userRedirectSession.deleteRedirectParams()],
      ],
    });
  }

  // Fetch teams data more efficiently
  let teams: ITeam[] = cache.has("teams")
    ? await cache.get("teams")
    : await useFetch({
        request,
        context,
        url: "/Team/details/",
        method: "GET",
        apiType: ApiEnum.Mob,
      }).then((teamsData) => {
        // Sort and cache in a single operation
        const sortedTeams = teamsData
          ?.sort(
            (a: ITeam, b: ITeam) =>
              (a.isArchived ? 1 : 0) - (b.isArchived ? 1 : 0)
          )
          .reverse();

        cache.set("teams", sortedTeams);
        return sortedTeams;
      });

  // Determine the active team based on the URL parameters or session data
  const teamId =
    searchParams.get("teamId") || session.get("teamId") || teams[0]?.id;

  let activeTeam =
    teams.length === 0
      ? ({} as ITeam)
      : cache.has(`activeTeam`)
      ? await cache.get(`activeTeam`)
      : await useFetch({
          request,
          context,
          url: `/Team/${teamId}`,
          method: "GET",
          apiType: ApiEnum.Mob,
        });

  if (!cache.has(`activeTeam`) && teams.length !== 0) {
    const teamsBoostLevel = await useFetch({
      request,
      context,
      url: "/team/boostdetails",
      method: "GET",
      apiType: ApiEnum.Mob,
    });
    //API TODO: add this to the team endpoint
    const boostStatus = teamsBoostLevel?.find(
      (team: ITeam) => team.id === teamId
    )?.boostStatus;
    //API TODO: add this to the team endpoint
    const address = teams?.find((team: ITeam) => team.id === teamId)?.address;

    if (activeTeam) {
      activeTeam = {
        ...activeTeam,
        address: address,
        boostStatus: boostStatus,
      };
    }

    cache.set(`activeTeam`, activeTeam);
  }

  // Update the session with the active team ID
  if (teams.length === 0) session.set("teamId", null);
  else session.set("teamId", teamId);
  // Commit session to get the cookie
  sessionCookie = await commitSession(session);
  // Fetch match data in parallel using Promise.all
  const fetchMatchData = async () => {
    const today = encodeURI(new Date().toUTCString());

    if (cache.has("matches") && cache.has("futureMatches")) {
      return {
        matches: cache.get("matches"),
        futureMatches: cache.get("futureMatches"),
      };
    }

    const [matches, futureMatchesData] = await Promise.all([
      useFetch({
        request,
        context,
        url: `/Team/matches?TeamId=${activeTeam.id}&FromUtc=${activeTeam?.season?.startsAtUtc}&ToUtc=${today}`,
        method: "GET",
        apiType: ApiEnum.Web,
      }),
      useFetch({
        request,
        context,
        url: `/match/upcoming?TeamId=${activeTeam.id}&Page=0&PageSize=200`,
        method: "GET",
        apiType: ApiEnum.Web,
      }),
    ]);

    const futureMatches = futureMatchesData?.matches;

    // Cache the results
    cache.set("futureMatches", futureMatches);
    cache.set("matches", matches);

    return { matches, futureMatches };
  };

  const { matches, futureMatches } = await fetchMatchData();

  // Sort matches by date
  if (matches?.length) matches?.sort(sortMatchByDateAsc);

  // Initialize players
  const matchStatsPlayers = initializePlayers(activeTeam?.players);

  // Process matches and update player statistics
  const { goalsScoredByTeam, assistsForTeam, awards } = processMatches(
    matches,
    matchStatsPlayers
  );

  // Rank players based on different statistics
  rankPlayers(matchStatsPlayers, "scoredGoals", "goalsScoredRank");
  rankPlayers(matchStatsPlayers, "assistedGoals", "assistsRank");
  rankPlayers(matchStatsPlayers, "awards.length", "awardsRank");
  rankPlayers(
    matchStatsPlayers,
    "memberTotalMinutesPlayed",
    "minutesPlayedRank"
  );

  // Calculate totals for the team
  const totals = calculateTotals(
    matches,
    goalsScoredByTeam,
    assistsForTeam,
    awards,
    matchStatsPlayers
  );

  // Return the data for the team pages, including various headers
  return data<TeamPagesData>(
    {
      user,
      activeTeam,
      teams,
      loggedInUser: updatedLoggedInUser,
      matches,
      futureMatches,
      showWelcomeMessage,
      ENVIRONMENT: context?.cloudflare?.env?.ENVIRONMENT,
      totals,
      matchStatsPlayers: matchStatsPlayers,
    },
    {
      headers: [
        ["Set-Cookie", sessionCookie],
        ["Set-Cookie", await onBoardingSession.deleteOnBoarding()],
        ["Set-Cookie", await userRedirectSession.deleteRedirectParams()],
      ],
    }
  );
};
export async function clientLoader({ serverLoader }: ClientLoaderFunctionArgs) {
  // Load server data
  const [serverData] = await Promise.all([serverLoader()]);

  // Return the server data
  return { ...(serverData || {}) };
}
clientLoader.hydrate = true;

export function HydrateFallback() {
  return <LoadingSkeleton />;
}
export default function LayoutPages() {
  const {
    user,
    teams,
    totals,
    matchStatsPlayers,
    matches,
    futureMatches,
    loggedInUser,
    showWelcomeMessage,
    activeTeam,
    ENVIRONMENT,
  } = useLoaderData();

  const { t } = useTranslation("translation");

  const { update } = useIntercom();

  useEffect(() => {
    if (loggedInUser) {
      MixPanel.initUserTracking({
        user: loggedInUser,
        team:
          teams?.length > 0
            ? {
                id: activeTeam?.organization?.id,
                name: activeTeam?.organization?.name,
              }
            : undefined,
        intercomUpdate: update,
      });
    }
  }, [loggedInUser?.email]);

  useEffect(() => {
    if (showWelcomeMessage) {
      window.modalWelcome.showModal();
    }
  }, [showWelcomeMessage]);

  useEffect(() => {
    if (loggedInUser) {
      MixPanel.identify(loggedInUser?.email);
      MixPanel.people.set({
        $email: loggedInUser?.email,
        user_id: loggedInUser?.id,
        $first_name: loggedInUser?.profile?.firstName,
        $last_name: loggedInUser?.profile?.lastName,
        $avatar: loggedInUser?.profile?.imageUrl,
      });
    }
  }, []);

  return (
    <>
      <ModalAdminRights rightsTitle="Admin or schedule" />
      {showWelcomeMessage && <ModalWelcome />}
      <ModalLogout />
      <ModalEditUser userLoggedIn={loggedInUser} />
      <ModalBoostLevelRights />
      <ModalTeamIsArchived />
      {teams?.length > 0 ? (
        <LayoutStructure
          loggedInUser={loggedInUser}
          activeTeam={activeTeam}
          teams={teams}
          ENVIRONMENT={ENVIRONMENT}
          user={user}
          totals={totals}
          matches={matches}
          futureMatches={futureMatches}
          matchStatsPlayers={matchStatsPlayers}
        />
      ) : (
        <NoTeamsMessage />
      )}
    </>
  );
}

export function usePagesContext() {
  return useOutletContext<TeamPagesData>();
}

export function ErrorBoundary() {
  const error: unknown = useRouteError();
  cache.clear();
  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>Oops</h1>
        <p>Status: {error.status}</p>
        <p>{error.data.message}</p>
      </div>
    );
  }

  // Don't forget to typecheck with your own logic.
  // Any value can be thrown, not just errors!

  let errorMessage =
    (error as { message?: string })?.message || "Unknown error";
  return (
    <div className="grid h-screen place-items-center">
      <div className="flex flex-col items-center max-w-lg">
        <div className="alert alert-error text-center flex flex-col gap-4">
          <h1>Uh oh ...</h1>
          <p>Something went wrong.</p>
          <p className="font-bold">{errorMessage}</p>
          <p>Refresh the page and try again</p>
          <a
            onClick={() => window.location.reload()}
            className="btn w-full whitespace-nowrap flex flex-col px-4"
          >
            Refresh
          </a>
          <p>Or you can try logout & login again</p>
          <Form method="post" action="/action/logout" className="mt-4">
            <MingleSubmitButton
              submitValue="logout"
              theme="btn-outline"
              label="logout"
              onClick={() => {
                MixPanel.track("mft_signOut_tap_button_signOut");
              }}
            />
          </Form>
        </div>
      </div>
    </div>
  );
}
