import React, { useEffect } from "react";
import Sidebar from "~/components/PagesLayoutComponents/sidebar/Sidebar";
import { Link, Outlet, useNavigation, href } from "react-router";
import { ButtonModalShareTeamPage } from "~/components/ButtonShareTeamPage";
import { DialogWindow } from "~/utils/api.interfaces.enums";
import { useTheme } from "~/utils/theme-provider";
import { MingleLogo } from "./sidebar/MingleLogo";
import CompactSidebar from "./sidebar/CompactSidebar";
declare let window: DialogWindow;
interface LayoutStructureProps {
  loggedInUser: any;
  activeTeam: any;
  teams: any[];
  ENVIRONMENT: string;
  user: any;
  totals: any;
  matches: any[];
  futureMatches: any[];
  matchStatsPlayers: any[];
}

/**
 * LayoutStructure component that handles the main application layout
 * Includes sidebar, header, and content area with responsive behavior
 */
export const LayoutStructure: React.FC<LayoutStructureProps> = ({
  loggedInUser,
  activeTeam,
  teams,
  ENVIRONMENT,
  user,
  totals,
  matches,
  futureMatches,
  matchStatsPlayers,
}) => {
  const [theme] = useTheme();
  const navigation = useNavigation();
  const isSubmitting =
    navigation.state === "submitting" || navigation.state === "loading";

  return (
    <div className="w-full flex h-full min-h-screen flex-row">
      <aside aria-label="Sidenav">
        <Sidebar
          loggedInUser={loggedInUser}
          activeTeam={activeTeam}
          teams={teams}
          isMobileSidebar={false}
        />

        <dialog
          id="sidebar"
          aria-label="Sidenav"
          className="modal m-0 p-0 dark:border-gray-200"
          data-theme={theme}
        >
          <Sidebar
            loggedInUser={loggedInUser}
            activeTeam={activeTeam}
            teams={teams}
            isMobileSidebar={true}
          />
          <form
            method="dialog"
            className="modal-backdrop fixed left-0 top-0 w-screen h-screen  backdrop-blur-xs"
          >
            <button>close</button>
          </form>
        </dialog>
      </aside>

      {/* Main content container with responsive width */}
      <div
        className={`flex w-full h-full sm:overflow-hidden transition-left duration-200 relative flex-1`}
      >
        {/* Mobile header */}
        <header
          className={`fixed top-0 z-100 flex w-full px-2 min-h-16 bg-white dark:bg-black border-b border-gray-100 dark:border-gray-800 xl:gap-2 flex-row justify-start items-center transform transition-all duration-200 lg:hidden`}
        >
          <div
            className="btn btn-square btn-ghost lg:hidden"
            onClick={() => {
              window.sidebar.showModal();
            }}
          >
            {isSubmitting ? (
              <div className="loading loading-spinner loading-sm text-primary"></div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-6 h-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            )}
          </div>

          <Link
            to={href("/")}
            className="flex flex-row items-center mr-auto lg:hidden"
          >
            <MingleLogo className="h-8 sm:h-10" />
          </Link>
          <div className="flex lg:hidden gap-4">
            <ButtonModalShareTeamPage
              inHeader={true}
              activeTeam={activeTeam}
              ENVIRONMENT={ENVIRONMENT}
            />
          </div>
        </header>

        {/* Main content area */}
        <div className="w-full mt-16 mx-auto p-3 lg:p-4 xl:p-6 lg:mt-0 flex-1">
          <Outlet
            context={{
              loggedInUser,
              user,
              totals,
              matches,
              futureMatches,
              teams,
              matchStatsPlayers,
              activeTeam,
              ENVIRONMENT,
            }}
          />
        </div>
      </div>
    </div>
  );
};
