import { TFunction } from "i18next";
import { href } from "react-router";
import { ITeam } from "~/utils/api.interfaces.enums";
// Import the team context

// Navigation items for the sidebar
export const navItems = (
  activeTeam: ITeam | undefined,
  t: TFunction<"translation", undefined>
) => [
  {
    url: href(`/`),
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m12 3.145-9 7.03v9.573a1.23 1.23 0 0 0 1.221 1.234h3.18v-5.935c0-.811.653-1.45 1.453-1.45H15.2c.8 0 1.454.639 1.454 1.45v5.935h3.125c.047 0 .095.003.14.01A1.231 1.231 0 0 0 21 19.766v-9.573l-6.925-5.42L12 3.145Zm9.23 7.227a.556.556 0 0 1-.011-.008l.011.008Zm-1.641 12.61H16.56a1.906 1.906 0 0 1-1.906-1.9v-5.486H9.4v5.936c0 .81-.654 1.45-1.454 1.45H4.221A3.23 3.23 0 0 1 1 19.748V9.904c0-.426.192-.862.563-1.145l9.544-7.456a1.465 1.465 0 0 1 1.786 0l.005.005 2.412 1.891 7.127 5.578c.37.283.563.72.563 1.145v9.844A3.23 3.23 0 0 1 19.779 23c-.065 0-.129-.006-.19-.018ZM2.769 10.354l.012-.008a.312.312 0 0 1-.011.008Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    title: t("navItems.home"),
  },
  {
    url: href(`/attendance/match/table`),
    icon: (
      <svg
        className="w-5 h-5 "
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 18"
      >
        <g clipPath="url(#clip0_3338_200)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75ZM9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.46967 8.46967C5.76256 8.17678 6.23744 8.17678 6.53033 8.46967L8.78033 10.7197C9.07322 11.0126 9.07322 11.4874 8.78033 11.7803C8.48744 12.0732 8.01256 12.0732 7.71967 11.7803L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256 5.46967 8.46967Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.4801 6.17383C12.7983 6.43901 12.8413 6.91193 12.5762 7.23014L8.82617 11.7301C8.56099 12.0483 8.08807 12.0913 7.76986 11.8262C7.45165 11.561 7.40866 11.0881 7.67383 10.7699L11.4238 6.26986C11.689 5.95165 12.1619 5.90866 12.4801 6.17383Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_3338_200">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    title: t("navItems.attendance"),

    subItems: [
      {
        url: href(`/attendance/match/stats`),
        title: t("navItems.matchAttendance"),
        urlActiveMatch: ["/attendance/match/stats", "/attendance/match/table"],
      },

      {
        url: href(`/attendance/training/stats`),
        title: t("navItems.trainingAttendance"),
        urlActiveMatch: [
          "/attendance/training/stats",
          "/attendance/training/table",
          "/attendance/training/table/finished",
          "/attendance/training/table/upcoming",
        ],
      },
      {
        url: href(`/attendance/minutes-played/stats`),
        title: t("navItems.minutesPlayed"),
        urlActiveMatch: [
          "/attendance/minutes-played/stats",
          "/attendance/minutes-played/table",
        ],
      },
    ],
  },
  {
    url: href(`/activities/match/:state`, { state: "finished" }),
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        // className="w-5 h-5 "
        fill="none"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11 2a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM0 11C0 4.925 4.925 0 11 0s11 4.925 11 11-4.925 11-11 11S0 17.075 0 11Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m11.15 2.65.37-1.846 1.96.392-.5 2.5a1 1 0 0 1-.784.785l-2.5.5a1 1 0 0 1-.977-.356l-2-2.5L8.281.875 9.9 2.9l1.25-.25Zm5.565 3.432 1.566-1.957-1.562-1.25-2 2.5a1 1 0 0 0-.076 1.14l1.5 2.5a1 1 0 0 0 .998.475l3.5-.5-.282-1.98-2.843.406-.8-1.334ZM14.72 13.875a1 1 0 0 1 1.227-.27l4 2-.894 1.79-3.277-1.639-1.095 1.37 1.213 2.427-1.788.894-1.5-3a1 1 0 0 1 .113-1.072l2-2.5ZM3.4 13.7a1 1 0 0 1 1.115-.057l2.5 1.5a1 1 0 0 1 .471 1.021l-.5 3-1.972-.328.388-2.329-1.34-.804L2.6 16.8l-1.2-1.6 2-1.5ZM2.5 7.22V4.5h2V8a1 1 0 0 1-.757.97l-2 .5-.486-1.94L2.5 7.22ZM8.31 9.33l-.168 1.505 1.463.731 1.07-1.07-.507-1.353-1.859.186ZM7.304 7.42a1 1 0 0 0-.894.884l-.334 3.003a1 1 0 0 0 .547 1.005l2.731 1.366a1 1 0 0 0 1.155-.188l2.035-2.035a1 1 0 0 0 .229-1.058L11.77 7.72a1 1 0 0 0-1.036-.643l-3.429.342Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    title: "Activities",
    subItems: [
      {
        url: href(`/activities/trends`),
        title: t("navItems.match"),
        urlActiveMatch: [
          "/activities/trends",
          "/activities/match",
          "/activities/match/finished",
          "/activities/match/upcoming",
        ],
      },
      {
        url: href(`/activities/training/:state`, { state: "finished" }),
        title: t("navItems.training"),
        urlActiveMatch: [
          "/activities/training",
          "/activities/training/upcoming",
          "/activities/training/finished",
        ],
      },
    ],
  },
  {
    url: href(`/schedule/match/:state`, { state: "upcoming" }),
    icon: (
      <svg
        className="w-5 h-5 "
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 16 18"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5 .75a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V1.5A.75.75 0 0 1 5 .75ZM11 .75a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V1.5A.75.75 0 0 1 11 .75ZM.5 6.75A.75.75 0 0 1 1.25 6h13.5a.75.75 0 0 1 0 1.5H1.25a.75.75 0 0 1-.75-.75Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14 4.5H2v11.25h12V4.5ZM2 3A1.5 1.5 0 0 0 .5 4.5v11.25a1.5 1.5 0 0 0 1.5 1.5h12a1.5 1.5 0 0 0 1.5-1.5V4.5A1.5 1.5 0 0 0 14 3H2Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    title: t("navItems.scheduling"),
    urlActiveMatch: [
      "/schedule/training/finished",
      "/schedule/training/upcoming",
      "/schedule/match/upcoming",
      "/schedule/match/finished",
    ],
  },
  {
    url: href(`/match-performance/stats`),
    icon: (
      <svg
        className="w-5 h-5 "
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 18"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m7.535.859 5.328 2.186c.55.223.887.76.887 1.336v3.634a.748.748 0 0 1-.004.08c-.138 1.277-.529 3.42-1.716 5.256-1.015 1.567-2.582 2.574-3.674 3.276-.205.132-.394.253-.559.365-.46.317-1.08.353-1.576.048l-.003-.002-.157-.097c-1.123-.694-2.945-1.818-4.09-3.59C.78 11.511.39 9.227.254 8.105a.75.75 0 0 1-.005-.09V4.38c0-.566.334-1.111.887-1.336L6.465.86a1.37 1.37 0 0 1 1.07 0ZM7 2.26 1.75 4.415v3.554c.135 1.083.497 3.048 1.48 4.568.957 1.48 2.52 2.448 3.675 3.162l.066.041c.206-.14.422-.28.645-.424 1.095-.71 2.333-1.512 3.154-2.78.988-1.528 1.348-3.37 1.48-4.562V4.415L7 2.261Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.982 12.003a.794.794 0 0 0-.485.247c-.173.171-.247.363-.247.5a.75.75 0 0 1-1.5 0c0-.623.301-1.179.69-1.565.39-.386.944-.68 1.557-.682m-.015 1.5a.86.86 0 0 1 .16.096c.11.076.209.159.335.264.078.064.166.138.273.223.497.397 1.232.914 2.25.914s1.753-.516 2.25-.913c.107-.086.196-.16.274-.224.126-.104.225-.187.334-.263a.866.866 0 0 1 .162-.097c.134.007.318.081.483.245.173.173.247.365.247.502a.75.75 0 0 0 1.5 0c0-.623-.3-1.18-.69-1.566-.39-.386-.945-.681-1.56-.681-.43 0-.779.21-1.006.37a8.764 8.764 0 0 0-.49.386l-.19.156C7.871 11.767 7.481 12 7 12c-.482 0-.872-.233-1.313-.585a14.968 14.968 0 0 1-.19-.156c-.157-.13-.336-.278-.491-.387-.228-.16-.578-.37-1.009-.37"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10 7.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-1.5 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    title: t("navItems.matchPerformance"),
  },

  {
    url: href(`/media`),
    icon: (
      <svg
        className="w-5 h-5 "
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M.75 6.75a1.5 1.5 0 0 1 1.5-1.5H13.5a1.5 1.5 0 0 1 1.5 1.5v9a1.5 1.5 0 0 1-1.5 1.5H2.25a1.5 1.5 0 0 1-1.5-1.5v-9Zm12.75 0H2.25v9H13.5v-9Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.25 2.094A1.5 1.5 0 0 1 4.993.896l10.906 1.982a1.5 1.5 0 0 1 1.209 1.739l-1.568 8.808a1.5 1.5 0 0 1-1.737 1.214l-.058-.01.26-1.477.059.01 1.567-8.808L4.724 2.371l-.697 3.712-1.475-.277.698-3.712Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M7.5 9.75a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9.91 12.898c-.115.116-.24.243-.38.382-.578.579-1.074.982-1.539 1.202-.512.244-.99.265-1.45.098-.331-.12-.642-.343-.882-.515l-.097-.07a4.546 4.546 0 0 0-.966-.549c-.366-.146-.788-.04-1.293.397-.498.429-.912 1.052-1.132 1.492l-1.342-.67c.28-.56.803-1.362 1.493-1.958.683-.589 1.698-1.107 2.832-.653.55.22.96.495 1.273.716l.09.064c.283.2.411.29.536.336.055.02.117.042.295-.043.227-.107.575-.36 1.122-.907l.328-.331c.391-.395.73-.738 1.026-.976.396-.32.879-.593 1.493-.498.528.081 1.01.422 1.479.804.35.286.775.666 1.289 1.127l.663.594-.996 1.12c-.257-.228-.49-.436-.701-.626-.498-.446-.881-.79-1.203-1.052-.479-.39-.677-.472-.759-.484-.014-.003-.025-.004-.058.009a1.093 1.093 0 0 0-.265.173c-.224.18-.482.44-.856.818Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    title: t("navItems.media"),
  },
  {
    url: href(`/manage-team`),
    icon: (
      <svg
        className="w-5 h-5 "
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9 6.75a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Zm-2.25.75a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM14.25 4.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM12 5.25a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM3.75 4.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Zm-2.25.75a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM3.5 9.75c-.547 0-1.25.616-1.25 1.744v1.256a.75.75 0 0 1-1.5 0v-1.256C.75 9.865 1.83 8.25 3.5 8.25h1a.75.75 0 0 1 0 1.5h-1ZM12.75 9a.75.75 0 0 1 .75-.75h1c1.672 0 2.75 1.628 2.75 3.253v1.247a.75.75 0 0 1-1.5 0v-1.247c0-1.132-.706-1.753-1.25-1.753h-1a.75.75 0 0 1-.75-.75Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.38 11.295c.042.022.095.055.158.104.175.137.373.358.561.657.379.6.651 1.408.651 2.194a.75.75 0 0 0 1.5 0c0-1.103-.371-2.185-.882-2.994a4.09 4.09 0 0 0-.905-1.038c-.332-.26-.764-.48-1.25-.467a.84.84 0 0 0-.332.079c-.06.028-.11.059-.143.08-.061.04-.128.089-.18.127l-.008.006c-.117.087-.243.18-.397.274-.309.187-.693.35-1.153.35-.47 0-.862-.158-1.176-.338-.155-.088-.284-.177-.403-.26l-.012-.008a3.714 3.714 0 0 0-.186-.124 1.234 1.234 0 0 0-.14-.073.865.865 0 0 0-.293-.074c-.493-.034-.93.186-1.26.442a3.997 3.997 0 0 0-.902 1.03c-.507.804-.878 1.885-.878 2.988a.75.75 0 0 0 1.5 0c0-.786.272-1.594.647-2.19.187-.296.382-.512.55-.643a.947.947 0 0 1 .148-.095 6.4 6.4 0 0 0 .485.31c.45.258 1.103.534 1.92.534.827 0 1.482-.295 1.93-.566.184-.111.338-.222.45-.305Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    title: "Team",
    subItems: [
      {
        url: href(`/manage-team`),
        title: t("navItems.team"),
        urlActiveMatch: ["/manage-team"],
      },
      {
        url: href(`/manage-team/opponents`),
        title: t("navItems.opponents"),
        urlActiveMatch: ["/manage-team/opponents"],
      },
      {
        url: href(`/manage-team/settings`),
        title: t("navItems.settings"),
        urlActiveMatch: ["/manage-team/settings"],
      },
    ],
  },
  {
    url: href(`/pricing`),
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10.342 1.857c.537-.54 1.439-.026 1.255.714l-1.09 4.382h3.056c.65 0 .991.778.551 1.26l-7.206 7.905c-.517.567-1.448.08-1.282-.672l1.122-5.073h-3.07c-.666 0-1-.81-.529-1.285l7.193-7.231Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    title: t("navItems.premium"),
    hide: activeTeam?.boostStatus?.boostLevel
      ? activeTeam?.boostStatus?.boostLevel > 0
      : false,
  },
];
