import { Form } from "react-router";
import { useTranslation } from "react-i18next";
import { MingleSubmitButton } from "~/components/form/MingleSubmitButton";
import { MixPanel } from "~/utils/MixPanel";
import { useTheme } from "~/utils/theme-provider";

type Props = {};

export default function ModalLogout({}: Props) {
  const { t } = useTranslation("translation");
  const [theme] = useTheme();
  return (
    <>
      <dialog className="modal" data-theme={theme} id="modalLogOut">
        <div className="modal-box">
          <div className="text-primary m-auto dark:text-secondary flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              fill="none"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M7.715 5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-1a1 1 0 1 1 2 0v1a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-9a1 1 0 0 0-1 1v1a1 1 0 1 1-2 0V5Z"
                clipRule="evenodd"
              />
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="m6.008 15.707-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 1.414L6.13 11h7.585a1 1 0 1 1 0 2H6.13l1.292 1.293a1 1 0 1 1-1.414 1.414Z"
                clipRule="evenodd"
              />
            </svg>
          </div>

          <h3 className="text-2xl font-bold mb-2 text-center">
            {t("titleModalLogout")}
          </h3>
          <div className="modal-action">
            <form method="dialog">
              <button className="btn btn-primary btn-outline btn-block">
                {t("teamsPage.buttonModalCancelLabel")}
              </button>
            </form>

            <Form method="post" action="/action/logout">
              <MingleSubmitButton
                submitValue="logout"
                label={t("buttonModalLogoutLabel")}
                onClick={() => {
                  MixPanel.track("mft_signOut_tap_button_signOut");
                }}
              />
            </Form>
          </div>
        </div>
        <form
          method="dialog"
          className="modal-backdrop fixed left-0 top-0 w-screen h-screen backdrop-blur-xs"
        >
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}
