import React from "react";
import { useTranslation } from "react-i18next";
import { MingleLogo } from "./sidebar/MingleLogo";

/**
 * NoTeamsMessage component
 * Displayed when a user has no teams
 * Encourages users to create their first team
 */
const NoTeamsMessage: React.FC = () => {
  const { t } = useTranslation("translation");

  return (
    <div className="min-h-screen w-full flex items-center justify-center">
      <div className="card max-w-lg m-auto">
        <div className="card-body">
          <div className="relative inline-flex m-auto items-center gap-2 mb-2 xl:mb-4 flex-col justify-center rounded-full text-blue-600">
            <MingleLogo className="h-16 w-16" />
            <h1 className="text-2xl sm:text-2xl font-[700] uppercase text-black dark:text-white">
              Homebase
            </h1>
          </div>
          <h1 className="text-3xl font-bold text-center text-gray-900 dark:text-gray-300">
            {t("teamsPage.noTeamFound")}
          </h1>
          <p className="mt-4 leading-relaxed text-gray-400 dark:text-gray-200 text-center">
            {t("teamsPage.noTeamFoundBody1")}
          </p>
          <p className="mt-4 leading-relaxed text-gray-400 dark:text-gray-200 text-center">
            {t("teamsPage.noTeamFoundBody2")}
          </p>
          <a
            href="/onboarding/team-setup/what-sport"
            className="btn btn-primary btn-outline mt-4"
          >
            {t("teamsPage.noTeamFoundLinkLabel")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default NoTeamsMessage;
