import { useNavigation } from "react-router";
import React from "react";
import { ITeam } from "~/utils/api.interfaces.enums";

export default function TeamNameAndLoader({
  activeTeam,
  showName = true,
}: {
  activeTeam: ITeam | undefined;
  showName?: boolean;
}) {
  let navigation = useNavigation();
  let isSubmitting =
    navigation.state === "submitting" || navigation.state === "loading";
  return (
    <>
      <div
        tabIndex={0}
        className={`btn btn-link p-0 flex text-white font-normal no-underline text-base flex-nowrap w-full mx-auto relative`}
      >
        {activeTeam?.logoUrl ? (
          <img
            alt=""
            src={activeTeam?.logoUrl}
            className={`h-8 w-8 rounded-full object-cover ${
              showName ? "mr-auto" : "m-auto"
            }`}
          />
        ) : (
          <div className="avatar placeholder  mr-auto">
            <div className="bg-neutral-focus text-neutral-content rounded-full w-8 mr-auto">
              <span className="text-md">{activeTeam?.nameAbbreviation}</span>
            </div>
          </div>
        )}

        {isSubmitting && (
          <span
            className={`loading loading-spinner loading-sm text-secondary z-30 ${
              !showName ? "absolute left-0 top-0" : ""
            }`}
          ></span>
        )}
      </div>
      {showName && (
        <div>
          <div
            className={`w-[200px] whitespace-nowrap text-left mr-auto text-ellipsis overflow-hidden font-[700]`}
          >
            {activeTeam?.name}
          </div>
          {activeTeam?.organization?.type === "club" && (
            <div
              className={`w-[200px] whitespace-nowrap text-left mr-auto text-ellipsis overflow-hidden`}
            >
              {activeTeam?.organization?.name}
            </div>
          )}
        </div>
      )}
    </>
  );
}
